
/*
 * Custom
 */
// alert('ok');

// initialization
// ===================================

// WOW.js
// ======
// new WOW().init(); // wow.js

// Tooltips & popover
// ==================
// $('[data-toggle="tooltip"]').tooltip(); // bt4 - tooltips
// $('[data-toggle="popover"]').popover();


// Download file
// =============
// $('.download').click(function() {
	// downloadFile("/patch-file");
	// return false;
// });

// Input date-time picker
// ======================
// jQuery('[name="datetime"]').datetimepicker({
// 	mask: '39 / 19 / 9999 23:59',
// 	format:'d/m/Y H:i'
// });
// jQuery.datetimepicker.setLocale('ru');


// Scrool to Top
// =============
// jQuery(function($) {
//   $('body').scrollToTop({
//     skin: 'null'
//   });
// });

// Scroll to Anchor
// ================
// var scroll = new SmoothScroll('a[href^="#"]', {
// 	speed: 500,
// 	offset: 30,
// 	// header: '[data-scroll-header]' // вычисляет отсуп от фиксированного элемента - по заданному селектору
// });


// custom Scripts
// ==============

var imgPath = {
	default: "img/Дом.png",
	chimney: "img/Дымоход.png",
	gutter: "img/Водосток.png",
	ventilation: "img/Вентиляция.png",
	caps_parapets: "img/колпаки-и-парапеты.png",
	flyugarki: "img/Флюгарки.png",
	additional: "img/Доборные-элементы.png",
	window_outflows: "img/Оконные-отливы.png",
	profiled_sheeting: "img/Профнастил.png",
	roofing_materials: "img/Кровельные-материалы.png",
	fence: "img/Забор.png",
	gate: "img/Ворота.png",
	skylights: "img/Мансардные-окна.png"
}

$('[el]').mouseenter(function() {
	var this_el = $(this);
	var el_name = this_el.attr("el");
	var el_nav = ".nav-map [el="+el_name+"]";

	if (el_name == "chimney") {var path_replace = imgPath.chimney}
	if (el_name == "gutter") {var path_replace = imgPath.gutter}
	if (el_name == "ventilation") {var path_replace = imgPath.ventilation}
	if (el_name == "caps_parapets") {var path_replace = imgPath.caps_parapets}
	if (el_name == "flyugarki") {var path_replace = imgPath.flyugarki}
	if (el_name == "additional") {var path_replace = imgPath.additional}
	if (el_name == "window_outflows") {var path_replace = imgPath.window_outflows}
	if (el_name == "profiled_sheeting") {var path_replace = imgPath.profiled_sheeting}
	if (el_name == "roofing_materials") {var path_replace = imgPath.roofing_materials}
	if (el_name == "fence") {var path_replace = imgPath.fence}
	if (el_name == "gate") {var path_replace = imgPath.gate}
	if (el_name == "skylights") {var path_replace = imgPath.skylights}

	$('img[usemap]').attr("src", path_replace);
	
	$(el_nav).attr("status", "current");
	// alert(path_replace);
});

$('[el]').mouseleave(function(event) {
	$('img[usemap]').attr("src", imgPath.default);
	$('.nav-map [el]').each(function(){
		$(this).attr("status", "no");	
	})
});
// 
var use_preloader = document.getElementsByClassName('preloader-page');

// если прелоадер используется
if (use_preloader) {
    jQuery(document).ready(function($) {
        setTimeout(function() {
            // $('.preloader-page').attr("preloaderPage", "no");
            $('body').attr("preloaderPage", "end");
            $('.preloader-page').remove();
        }, 700);
    });
} 

